.datepicker {
  td.active {
    background: lightgray;
  }

  td.disabled {
    color: lightgray;
  }
}

.cld-dropdown {
    z-index: 10;
    display: block;
    position: absolute;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}

.cld-main {
  width: 100%;
  padding: 3%;
}

.cld-main a {
  color: #333;
  font-weight: bold;
}

.cld-datetime {
  position: relative;

  margin: auto;

  text-align: center;
  font-family: $font-family-serif;
  font-size: 18px;
  margin-bottom: 5px;
  background-color: $brand-light;
  color: $brand-primary;
}


.cld-nav {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.cld-nav:hover {
  cursor: pointer;
}

.cld-nav:hover svg {
  fill: #666;
}

.cld-rwd {
  float: left;
}

.cld-fwd {
  float: right;
}

.cld-nav svg:hover {}

.cld-labels {
  padding-left: 0;
  background-color: rgba($brand-dark, 0.75);
  color: $brand-light;
  margin-bottom: 0;
}

.cld-days {
  padding-left: 0;
  background-color: rgba($brand-light, 0.25);
}

.cld-label, .cld-day {
  display: inline-block;
  width: 14.28%;
  text-align: center;
}

.cld-day.disableDay > .eventday{
  background-color: rgba(1,129,0, 0.5);
  border: rgba(1,129,0, 0.5);

}

.cld-day.nextMonth, .cld-day.prevMonth {
  opacity: 0.33;
}

.cld-number {
  position: relative;
  margin: 2px;
  padding: 5px;
}

.cld-title {
  position: absolute;
  z-index: 5;
  display: none;
  top: 30px;
  left: 0;
  padding: 5px 10px;
  background: $brand-light;
  white-space: nowrap;
  border: 1px solid $brand-md;
  border-radius: 5px;
  font-size: 12px;
  color:black;
}

.cld-number:hover .cld-title {
  display: block;
}

.cld-title::before {
  content: '';
  position: absolute;
  top: -7.5px;
  left: 7.5px;
  width: 0;
  height: 0;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;
  border-bottom: 7.5px solid #ccc;
}

.cld-number.eventday {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  border: 1px solid $brand-primary;
  background-color: $brand-primary;
  color: $brand-light;
  line-height: 19px;
}

.cld-number.eventday:hover {
  cursor: pointer;
  background-color: $brand-hover;
  border: 1px solid $brand-hover;
  color: $brand-light;
}
