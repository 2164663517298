@import "_variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "~font-awesome/scss/font-awesome";

@import "~bootstrap-timepicker/css/bootstrap-timepicker.css";

@import "calendar.scss";

body {
  color: $brand-dark;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.45;
  padding: 0;
  background-color: $body-bg;
}

h1, h2, h3, h4, h5, h6,
.panel-heading h2 {
  font-family: $font-family-serif;
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
}

h1 {
  color: $brand-dark;
}

h2, h3, h4, h5, h6 {
  color: $brand-primary;
}

a.btn-default {
  color: $brand-light !important;
}

h1 {
  margin: 0 0 20px;
}

.align-bottom {
  vertical-align: middle;
}

.screen-reader {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.form-control:focus {
  border-color: $brand-secondary;
  outline: 0;
  box-shadow: inset 0 1px 1px $brand-secondary, 0 0 8px $brand-secondary;
}

.header-notification {
    color: $brand-primary;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    background-color: #eee;
    border-color: #ddd;
}

.booking {
  padding: 20px 40px;
}

.equipment-rentals > div.col-lg-12 {
  padding-left: 0;
  padding-right: 0;
}

.equipment-rentals .panel-heading {
  background-color: #000;
}

.equipment-rentals .form-group {
  padding: 0 10px 0 0;
}

.equipment-rentals .btn-danger {
  vertical-align: 20px;
}

.camp-site-view {
  margin-bottom: 30px;

  .features {
    margin-top: 30px;
  }
}

.camp-site-view .list-group-item {
  border: none;
}

.camp-site-view .list-group-item:nth-child(2n) {
  background-color: $brand-highlight;
}

.camp-site-view .badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: $brand-primary;
  border-radius: 10px;
}

.camp-site-view h2 {
  margin-top: 0;
}

.highlight {
  background-color: $brand-highlight;
}

/* ----------- */
/* General CSS */
/* ----------- */

a h4 {
  color: $brand-hover;
}

a:hover h4 {}

.pull-right a {
  font-size: 16px;
  font-weight: bold;
  color: $brand-primary;
}

.pull-right a.btn {
  font-weight: normal;
  color: $brand-light;
}

.full-green {
  background-color: $brand-primary;
  margin-top: 2em;
}

/* ----------- */
/* Header CSS  */
/* ----------- */

.navbar-toggle {
  background-color: $brand-primary;
  border: 1px solid $brand-dark;
}

.navbar-toggle .icon-bar {
  background-color: $brand-light;
}

.navbar {
  margin-top: 20px;
  min-height: 94px;
  margin-bottom: 50px;
}

.navbar-nav {
  margin: 71px -15px 8px;
}

.nav li a {
  color: $brand-primary;
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
}

.nav li a:hover {
  color: $brand-hover;
}

.logo img {
  width: 100%;
}

/* -- Menu  -- */

.hamburger > a {
  color: $brand-primary;
  float: right;
  padding-top: 45px;
}

.cross > a {
  float: right;
  color: $brand-hover;
  padding-bottom: 20px;
}

.main-nav-menu li {
  list-style-type: none;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}

.main-nav-menu li a {
  color: $brand-primary;
}

.main-nav-menu li a:hover {
  color: $brand-hover;
}

.main-nav-menu > a:hover {
  color: $brand-hover;
}

@media (max-width: 720px) {
  .page-header img {
    width: 100%;
  }
}

/* Button */

.btn-default {
  padding: 5px 20px;
  font-size: 16px;
}

.btn-default.btn-xs {
  padding: 5px 10px;
  font-size: 14px;
}

.btn-default:hover {
  background-color: $brand-secondary;
  border: $brand-secondary;
}

/* ------------ */
/* SideBar CSS  */
/* ------------ */

.border-green {
  border: solid 4px $brand-primary;
  border-radius: 4px;
}

.trip-date {
  width: 100%;
  padding-bottom: 5px;
}

.trip-date input {
  text-align: right;
  float: right;
  border-radius: 5px;
  border: none;
}

.number > .form-control {
  width: 50px;
  border: none;
}

.number label {
  margin-right: 5px;
}

@media (max-width: 992px) {
  .options {
    display: none;
  }

  .options-mobile {
    border: solid 2px $brand-primary;
    text-align: center;
    font-size: 16px;
    color: #fff;
    background-color: $brand-primary;
  }

  .options-hide {
    border: solid 2px $brand-primary;
    text-align: center;
    display: none;
    font-size: 16px;
    color: #fff;
    background-color: $brand-primary;
  }
}

@media (min-width: 993px) {
  .options-mobile {
    display: none !important;
  }

  .options-hide {
    display: none !important;
  }

  .options {
    display: inherit !important;
  }
}

/* ------------ */
/* Content Area */
/* ------------ */

/* -- Facilities -- */

.camp-properties {
  padding: 0;
  margin-bottom: 50px;
  box-shadow: 2px 2px 4px rgba($brand-dark, 0.15);
  text-align: center;
  margin-right: 50px;
  position: relative;
  background-color: rgba($brand-dark, 0.01);

  .info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    h3,
    h3 a {
      color: $brand-light;
    }
  }

  &:nth-child(3) {
    margin-right: 0;
  }

  img {
    width: 100%;
  }
}

h1 a.btn {
  margin-top: 12px;
}

/* -- Amenity -- */

.filter {
  padding-bottom: 20px;
  padding-left: 0;

  h3 {
    margin-top: 0;
  }

  h5 {
    font-weight: $font-black;
    font-size: 18px;
  }

  .btn-group {
    margin-bottom: 5px;
  }

  .btn-default {
    margin-right: 10px;
    background-color: transparent;
    border: 1px solid $brand-md;
    color: $text-color;
  }

  .btn-default:hover,
  .btn-default:active {
    background-color: $brand-md;
  }

  .btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
    color: $text-color;
    background-color: $brand-md;
    border-color: $brand-md;
  }

  .dropdown-menu {
    padding: 10px;
  }
}

:checked + span {
  font-weight: $font-black;
  color: $brand-primary;
}

.camp-amenity {
  padding: 2.5%;
  background-color: $brand-highlight;
  margin-bottom: 20px;
  box-shadow: 2px 2px 4px rgba($brand-dark, 0.15);

  a, .cld-title {
    color: $brand-primary;
  }

  img {
    width: 100%;
  }

  h3 {
    margin-top: 0;
  }

  .description {
    margin-top: 25px;
    margin-bottom: 35px;
  }
}

.filter-campsites {
  max-width:250px;
}

/* -- Checkout -- */
#stripe-payment {
  display: none;
}

.cart table h4,
.cart table h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.cart td p {
  line-height: 14px;
}

.cart td.align-bottom {
  vertical-align: bottom;
}

.cart .highlight {
  padding: 20px;
}

.cart fieldset {
  margin-bottom: 40px;
}

.cart legend {
  float: left;
  display: inline-block;
  color: $brand-primary;
}

/* Social Icons */

.footer .fa {
  background-color: $brand-hover;
  padding: 5px 9px;
  border-radius: 45px;
  color: $brand-light;
  margin-top: 10px;
  cursor: pointer;
}

.footer .fa:hover {
  background-color: $brand-secondary;
}

/* ------------ */
/* Footer Area  */
/* ------------ */

.footer {
  color: $brand-light;
}

.footer .btn {
  margin-top: 10px;
}

.footer-header {
  color: $brand-light;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-header a {
  color: $brand-hover;
}

.footer-header-green {
  color: $brand-light;
  background-color: $brand-primary;
  text-align: center;
  padding: 10px;
}

.footer-header-green h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font-family-serif;
}

.footer-content {
  padding-left: 0;
  padding-right: 0;
  color: $brand-light;
  border: solid 2px $brand-primary;
}

.footer-content-camp {
  border-top: solid 2px $brand-primary;
  border-right: solid 2px $brand-primary;
  border-bottom: solid 2px $brand-primary;
  color: $brand-light;
  padding-left: 0;
  padding-right: 0;

  h4 {
    color: $brand-light;
  }
}

.footer-content h4 {
  font-family: $font-family-serif;
  line-height: 1.4;
  margin-top: 0;
  color: $brand-light;
}

.footer-content p {
  font-size: 14px;
}

.footer-content a {
  color: $brand-primary;
  font-size: 18px;
}

.footer {
  padding-bottom: 30px;
  background: $brand-primary;
}

.footer.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.footer-content-camp > .camp {
  margin: 20px;
  padding-left: 0;
  padding-right: 0;
}

.row > [class*='col-'].footer {
  flex-direction: column;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .footer-content > .col-md-3 {
    width: 20%;
    margin: 20px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .footer.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: block;
  }

  .footer-content-camp {
    border: solid 2px $brand-primary;
    color: $brand-light;
    padding-left: 0;
    padding-right: 0;
  }
}

.match-content {
  width: auto;
}
