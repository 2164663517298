// Fonts
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,700,900);

// Body
$body-bg: #ffffff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #018100;
$brand-secondary: #428bca;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #ff6207;
$brand-highlight: #eaeaea;
$brand-dark: #000;
$brand-md: #ccc;
$brand-light: #fff;
$brand-hover: #e01a23;

// Typography
$font-family-sans-serif: "Helvetica", Verdana, Arial, sans-serif;
$font-family-serif: "Merriweather", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: $brand-dark;
$font-bold: 700;
$font-black: 900;

// Navbar
$navbar-default-bg: #fff;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

//Buttons
$btn-default-color: $brand-light !default;
$btn-default-bg: $brand-hover !default;
$btn-default-border: $brand-hover !default;
